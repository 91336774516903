import { Component, ElementRef, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationError, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';
import { VERSION as VERSION_ALAIN } from '@delon/theme';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VERSION as VERSION_ZORRO } from 'ng-zorro-antd/version';

import { environment } from '@env/environment';

import { FreshdeskService } from './freshdesk.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet />
  `,
  imports: [RouterOutlet]
})
export class AppComponent {
  constructor(
    el: ElementRef,
    renderer: Renderer2,
    private router: Router,
    private modalSrv: NzModalService,
    public translate: TranslateService,
    private freshdesk: FreshdeskService
  ) {
    renderer.setAttribute(el.nativeElement, 'ng-alain-version', VERSION_ALAIN.full);
    renderer.setAttribute(el.nativeElement, 'ng-zorro-version', VERSION_ZORRO.full);
    // this language will be used as a fallback when a translation isn't found in the current language
    // translate.addLangs(['de', 'en']);
    // translate.setDefaultLang('en');
    // console.log(translate.getLangs());

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // translate.use('de');

    let configLoad = false;
    this.router.events.subscribe(ev => {
      if (ev instanceof RouteConfigLoadStart) {
        configLoad = true;
      } else if (configLoad && ev instanceof NavigationError) {
        console.error(ev);

        let modalRef = this.modalSrv.create({
          nzTitle: `Navigationsfehler`,
          nzContent: environment.production
            ? `Mit der Navigation ist etwas schief gegangen.`
            : `Konnte nicht zu folgender Adresse navigieren：${ev.url}`,
          nzOnCancel: () => {
            modalRef.close();
          },
          nzFooter: [
            {
              label: 'Abbrechen',
              type: 'default',
              onClick: () => {
                modalRef.close();
              }
            },
            {
              label: 'Neuladen (ohne Cache)',
              danger: true,
              onClick: () => {
                modalRef.close();
                localStorage.clear();
                location.reload();
              }
            },
            {
              label: 'Neuladen',
              type: 'primary',
              onClick: () => {
                modalRef.close();
                location.reload();
              }
            }
          ]
        });
      } else if (ev instanceof NavigationEnd) {
        // this.titleSrv.setTitle();
        this.modalSrv.closeAll();
        this.freshdesk.FreshworksWidget('prefill', 'ticketForm', {
          custom_fields: { cf_document_url: window.location.href }
        });
      }
    });
  }
}
